<template>
    <header class="page-header">
        <div class="header-content">
            <div class="left">
                <router-link to="/"><img src="../../assets/images/pc/logo.png" /> </router-link>
            </div>
            <div class="nav">
<!--                <div class="nav-item">-->
<!--                    <span @click="goto('/')">{{$t('menu.home')}}</span>-->
<!--                </div>-->
<!--                <div class="nav-item">-->
<!--                    <span @click="goto('/bridge')">Bridge</span>-->
<!--                </div>-->
            </div>
            <div class="right">
                <wallet />
                <div class="lang" @click="toggleLang">
                    <img src="../../assets/images/lang.png" />
                    <span>{{cpd_lang}}</span>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import {local} from '../../config/constant'
import wallet from "./wallet"
export default {
    name: 'Header',
    components: {
        wallet
    },
    data() {
        return {
        }
    },
    computed: {
        cpd_lang() {
            if (this.$i18n.locale === 'en-US') {
                return '中文'
            } else {
                return 'English'
            }
        }
    },
    methods: {
        goto(path) {
            this.$router.push(path)
        },
        toggleLang() {
            let v = this.$store.state.lang
            if (v === 'zh-CN') {
                v = 'en-US'
            } else {
                v = 'zh-CN'
            }
            this.$store.commit('setLang', v)
            this.$i18n.locale = v
            localStorage.setItem(local.LOCALE, v)
        }
    }
}
</script>

<style lang="less">
.page-header {
    display: flex;
    height: 60px;
    border-bottom: solid 1px #000;
    justify-content: center;
    .header-content {
        flex: 1;
        max-width: 1920px;
        display: flex;
        justify-content: space-between;
        .left {
            display: flex;
            padding-left: 36px;
            a {
                display: flex;
                align-items: center;
                img {
                    height: 60%;
                }
            }
        }
        .nav {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .nav-item {
                padding-left: 46px;
                span {
                    font-weight: bold;
                    font-family: ChalkboardBold;
                    cursor: pointer;
                    font-size: 16px;
                }
            }
        }
        .right {
            width: 322px;
            display: flex;
            justify-content: flex-end;
            .lang {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 24px;
                margin-left: 24px;
                cursor: pointer;
                span {
                    width: 62px;
                    padding-left: 6px;
                    font-size: 18px;
                }
            }
        }
    }
}
</style>
